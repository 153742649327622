@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
    body {
        margin: 0 !important;
    }
}

.main-container {
    --ckeditor5-preview-sidebar-width: 294px;
    --ckeditor5-preview-height: 777px;
    font-family: 'Lato';
    margin-left: auto;
    margin-right: auto;
}

.ck-content {
    font-family: 'Lato';
    line-height: 1.6;
    word-break: break-word;
}

.editor-container__editor-wrapper {
    width: 100%;
    display: flex;
    /*width: fit-content;*/
}

.editor-container_document-editor {
    border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
    display: flex;
    position: relative;
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
    flex-grow: 1;
    width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__menu-bar > .ck.ck-menu-bar {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
    max-height: var(--ckeditor5-preview-height);
    min-height: var(--ckeditor5-preview-height);
    overflow-y: scroll;
    background: var(--ck-color-base-foreground);
}

.editor-container_document-editor .editor-container__editor {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 28px;
    margin-bottom: 28px;
    height: 100%;
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    min-width: 215.9mm;
    max-width: 215.9mm;
    min-height: 279.4mm;
    height: fit-content;
    padding: 3mm 4mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
    margin-left: auto;
    margin-right: auto;
}


.editor-container__sidebar {
    min-width: var(--ckeditor5-preview-sidebar-width);
    max-width: var(--ckeditor5-preview-sidebar-width);
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
}

.editor-container__minimap-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.editor-container__sidebar_minimap {
    margin: 0;
    flex: 0 0 auto;
    border-left: 1px solid var(--ck-color-toolbar-border);
    position: relative;
    overflow: hidden;
    max-height: 100%;
}

.editor-container_include-minimap.ck-minimap__iframe-content {
    background: hsl(0, 0%, 100%);
}

.editor-container_include-minimap.ck-minimap__iframe-content .ck.ck-editor__editable {
    font-family: 'Lato';
    line-height: 1.15;
    flex: 1 1 auto;
    word-break: break-word;
    box-sizing: border-box;
    min-width: 215.9mm;
    max-width: 215.9mm;
    min-height: 279.4mm;
    height: fit-content;
    padding: 3mm 4mm;
}

.editor-container_include-minimap .editor-container__editor-wrapper {
    height: 100%;
}

.editor-container_include-minimap .editor-container__sidebar_minimap {
    max-width: calc(var(--ckeditor5-preview-sidebar-width) / 2);
    min-width: calc(var(--ckeditor5-preview-sidebar-width) / 2);
}

.ck-content {
    font-family: 'Lato', sans-serif;
    line-height: 1.15;
    word-break: break-word;
}

.ck-content p {
    margin: 0 0 8pt;
}

.editor-container__sidebar_minimap {
    overflow: visible !important;
    height: auto !important;
    max-height: 100% !important;
    width: 100% !important;
}