  .open, .closed{
    width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    width: fill-available !important;
  }
  .containerPanel{
    background: #fff;
    padding: 16px;

  }
  .formContainer {
    min-width: 258px;
    min-height: 642px;
    padding: 16px;
    background: #fff;
}

.containerPanel .css-mhc70k-MuiGrid-root{
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    height: 100% !important;

}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
.formContainerInner {
    height: 100%;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #D6DCEB;
    margin-bottom: 20px;
}
.formTitle {
    font-size: 10px;
    font-weight: 800;
    line-height: 14px;
    margin-top: -30px;
    color: #666666 !important;
    position: relative;
    background-color: white;
    padding: 6px;
    padding-left: 0;
    width: fit-content;
}